<template>
  <div>
    <v-container>
      <v-row class="fill-height" align-content="center" justify="center" v-if="loading">
        <v-col cols="12">
          <v-skeleton-loader v-bind="attrs" type="list-item-avatar-three-line, image, article"
          ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>

      <AuthorView v-if="!loading" :item="author" :showRestrictArea="true" :showProject="true"/>
  </div>
</template>
<script>
import AuthorView from '../components/author/View';
import { HOME } from '../routes';
import { mapState, mapActions } from 'vuex';

export default {
    data: () => ({
      attrs: {
        class: 'mb-6'
      },
    }),
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchDetail();
    },
    computed: {
        id() {
            return this.$user.info.id;
        },
        ...mapState('auth', ['user']),
        ...mapState('author', ['author', 'loading'])

    },
    methods: {
        ...mapActions('author', ['detailById']),
        fetchDetail() {
            this.detailById({id: this.id});
        }
    },
    components: {
        AuthorView,
    }
}
</script>